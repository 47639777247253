<template>
  <apexchart
    ref="ChartLine"
    :width="chartWidth"
    :height="chartHeight"
    :type="chartType"
    :options="options"
    :series="series"
  >
  </apexchart>
</template>
<script>
export default {
  props: [
    "chartType",
    "chartWidth",
    "chartHeight",
    "chartData",
    "chartLabels",
    "chartOptions",
    "refresh"
  ],
  data: () => ({
    series: [],
    options:{},
  }),
  mounted() {
    setTimeout(() => {this.getData()}, 1000);
  },
  methods: {
    getData() {
      this.series = this.chartData;
      this.options = this.chartOptions;
      console.log("this.chartData", this.chartData);
      console.log("this.chartOptions", this.chartOptions);
    },
  },
  watch: {
    refresh: function () {
      this.$refs.ChartLine.updateOptions(this.chartOptions);
      this.$refs.ChartLine.updateSeries(this.chartData);
    },
  },
};
</script>

<style lang="scss">
.apexcharts-tooltip {
  background: white !important;
}
</style>