<template>
  <div id="data-overview">
    <v-row class="justify-start">
      <v-col cols="12" sm="12">
        <div class="display-1">
          ShareThis Insight
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#94d227"
            elevation="0"
            >Next</v-btn
          >
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#94d227"
            elevation="0"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <v-col cols="12" sm="12" class="mt-6">
        <v-select
          class="float-right"
          :items="categories"
          v-model="selectCat"
          @change="selectCategory()"
          label="Choose category"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>
     <v-row v-if="loading.table && loading.chart">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="justify-start">
        <v-col cols="12" sm="12">
          <v-card elevation="0" outlined class="mx-auto">
            <DataOverviewChart
              chartHeight="300"
              chartType="area"
              :chartOptions="chart_line.options"
              :chartData="chart_line.series"
              :refresh="refresh"
              :show="show"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-10" id="events">
        <v-col cols="12" sm="12" class="mb-4">
          <div class="display-6 font-weight-bold">ShareThis Events Data</div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-data-table
            show-select
            :item-key="item__key"
            v-model="selected"
            :single-select="singleSelect"
            :headers="table__header"
            :items="table__data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
          ></v-data-table>
          <v-pagination
            id="events-pagination"
            :total-visible="7"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DataOverviewChart from "./Charts/DataOverviewChart.vue";
export default {
  components: { DataOverviewChart },
  data() {
    return {
      show:true,
      refresh:false,
      chart_line:{
        series:[],
        options:{}
      },
       loading: {
        chart: true,
        table: true,
      },
      selectCat:'',
      item__key:"id",
      singleSelect: false,
      table__header: [],
      table__data: [],
      page: 1,
      itemsPerPage: 5,
      pageCount: 0,
      selected: [],
      categories: [],
      chart__data: {},
      forceRender: 1,
      approchName: this.$store.getters.getApproch,
      tierName: this.$store.getters.getSegBtn,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    selectCategory(){
      this.refresh=false;
      this.show=false; 
      this.getDataByCategory()   
      this.sharethis_data_finalpage()   
    },
    async getCategories() {
      await this.$http
        .post("/sharethis_insight_options?clustering_technique="+this.approchName+"&tier="+this.tierName)
        .then((res) => {
          console.log("/sharethis_insight_overtime",res.data);
          this.categories=res.data;
          this.selectCat=res.data[0]
          this.getDataByCategory();
          this.sharethis_data_finalpage();
          this.show=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDataByCategory() {
      await this.$http
        .post("/sharethis_insight_overtime?option="+this.selectCat)
        .then((res) => {
          console.log("/chart_line",this.chart_line);
          this.chart_line.series=res.data.series;
          this.chart_line.options=res.data.options;
          this.refresh=true;
          this.show=true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async sharethis_data_finalpage() {
      await this.$http
        .post("/sharethis_events_categories?category="+this.selectCat)
        .then((res) => {
          console.log("--sharethis_events_categories--->", res.data);
          this.format__data(res.data);
          this.forceRender++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    format__data(data) {
      this.table__header=[]
      this.table__data=[]
      Object.keys(data[0]).forEach((el) => {
        this.table__header.push({ text: el, value: el, align: 'center'  });
      });
      this.item__key = Object.keys(data[0])[0];
      console.log("this.table__header ==>", this.table__header);
      data.forEach((el) => {
        console.log("el.concepts",el.concepts[0]);
        el.asn = el.asn.number;
        el.concepts = el.concepts.length ? el.concepts[0].text : "";
        el.keywords = el.keywords.length ? el.keywords[0].text : "";
        el.geo = el.geo.city;
        el.categories = el.categories[0];
        this.table__data.push(el);
      });
       this.loading = {
        chart: false,
        table: false,
      };
      console.log("this.table__data ==>", this.table__data);
    },
    next() {
      this.$store.commit("setStepper", { type: "sharethis", val: 2 });
      this.$router.push({ path: "/activation" });
    },
  },
};
</script>

<style lang="scss" scoped>
#data-overview::v-deep {
  .v-data-table-header  {
  background: #e5f5c8 !important;
}
  .apexcharts-legend-marker {
    border-radius: 2px !important;
  }
  .apexcharts-legend {
    padding: 15px;
    background: #f2f2f2;
    border-radius: 5px;
    height: fit-content;
  }
  #events-pagination .theme--light.v-pagination .v-pagination__item--active {
    background-color: #ffffff !important;
    border-color: #94d227 !important;
    color: #94d227 !important;
  }
  #events-pagination button {
    box-shadow: none !important;
    border: 1px solid rgb(172 174 178) !important;
  }
  #events-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"])
    *,
  #events-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"])
    * {
    color: white;
  }
  #events-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"]),
  #events-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"]) {
    background: #94d227;
  }
  #events-pagination {
    position: absolute !important;
    top: 0;
    right: 0;
  }
  #events {
    position: relative !important;
  }
}
</style>